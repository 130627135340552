html {
	scroll-behaviour: smooth;
}
body {
	background: $black;
	@include body-font;
	overflow-x: hidden;
	color: $white;
	letter-spacing: 0.05em;
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
	color: #fff;
}
::selection {
  background: $primary;
  text-shadow: none;
	color: $white;
}

a {
	@include smoothtrans();
  &:hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5 {
	@include body-font;
	text-transform: uppercase;
}

h1 {
	font-size: 1.2rem;
	letter-spacing: 0.5rem;
}
h2 {
	font-size: 5rem;
	letter-spacing: 0.05em;
	@include media-breakpoint-down(xs) {
		font-size: 4rem;	
	}
	span {
		display: block;
		font-size: 2rem;
	}
	span.red {
		font-size: 5rem;
		display: inline;
		color: $primary;
		@include media-breakpoint-down(xs) {
			font-size: 4rem;	
		}
	}
	&:after {
		content: "";
		width: 5rem;
		height: 4px;
		background-color: $primary;
		display: block;
		margin: 1rem auto 1.5rem;
	}
}
h3 {
	font-size: 2.5rem;
	letter-spacing: 0.05em;
	span {
		font-size: 1.2rem;
		display: block;
	}
	&:after {
		content: "";
		width: 5rem;
		height: 3px;
		background-color: $primary;
		display: block;
		margin: 1rem auto 1.5rem;
	}
}
h4 {
	font-size: 2rem;
	letter-spacing: 0.05em;
}
h5 {
	font-size: 1.1rem;
	color: $primary;
	letter-spacing: 0.1em;
}

p {
	font-size: 0.8rem;
	letter-spacing: 0.15em;
	@include media-breakpoint-down(xs) {
		font-size: 1rem;
		line-height: 1.4;
	}
}

.line {
	width: 1px;
	background-color: $primary;
	margin-left: auto;
	margin-right: auto;
}
.btn {
	border-radius: 0;
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 0.1em;
	padding: 0.5rem 1.5rem;
  outline: none !important;
	box-shadow: none!important;
}

select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-border-radius: 0px;
	/*background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") no-repeat;
  background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: 30px;*/
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat #fff;
  background-size: 12px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
}

.container {
  @media (min-width: 1400px) {
    max-width: 1300px;
  }
} 

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}