.insight {
	&::after {
		background-image: url(../img/insight/insight-bg.jpg);
    background-position: 50% 50%;
  }
  
  .frame {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 35px;
    margin-bottom: 35px;
    padding: 25px;
  }

  .alpha {
    margin-top: 100px !important;
  }

  .alpha ol {
    list-style-type: lower-alpha;
  }

  .alpha figure {
    margin-bottom: 45px;
  }

  .frame ol {
    counter-reset: list;
  }
  .frame ol > li {
    list-style: none;
    font-weight: 500;
  }
  .frame ol > li:before {
    content: counter(list) ") ";
    counter-increment: list;
  }
}

.insight-list {
  .card {
    border-radius: 0;
    border: none;
    @include media-breakpoint-down(md) {
      flex: auto;
    }
    .card-col {
      overflow: hidden;
      background: $black;
      .insight-image {
        transition: .4s ease-in-out;
        img {
          border-radius: 0;
        }
      }
      &:hover {
        .insight-image {
          opacity: .5;
          transform: scale(1.1);
        }
      }
    }
    .card-body {
      padding: 1.5rem 2.5rem 1rem;
      background-color: $darkgrey;
      .type {
        text-transform: uppercase;
        font-size: 0.8rem;
        letter-spacing: 1.5pt;
        margin-bottom: 1rem;
        a {
          color: $gray-600;
        }
      }
      .card-title {
        text-transform: none;
        @include heading-font();
        font-size: 1.4rem;
        line-height: 1.5;
        max-width: 90%;
        min-height: 60px;
        a {
          color: $white;
          &:hover {
            
          }
        }
      }
    }
    .card-footer {
      background-color: $darkgrey;
      border-radius: 0;
      border: none;
      small {
        @include body-font();
      }
    }
    
  }
}

.sticky {
  width: 100px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 20px !important;
}

.section--s1 {
  background: #333 !important;
  top: 40px !important;
  max-height: calc(100vh - 40px) !important;/* prevents hidden content due to the top position */
}


.parallax-window {
  min-height: 660px;
  background: transparent;
}

@media screen and (max-width: 992px) {
  .parallax-window {
    min-height: 360px;
  }
}