.services {
	&::after {
		background-image: url(../img/services/services-bg.jpg);
		background-position: 80% 90%;
	}
}

.services-list {
	h3 {
		font-size: 1.7rem;
		margin-top: 2rem;
		&:after {
			margin: 0.4rem auto 0;
		}
	}
	p {
		margin-bottom: 0;
	}
	.line {
		height: 3rem;
	}
}

.highlight-text {
	color: #e30613;
}

.container-services {
	position: relative;
  text-align: center;
}

.container-services img {
	opacity: 0.3;
}

.container-services .centered {
	font-size: 20px;
	position: absolute;
	color: white;
  top: 55%;
  left: 50%;
	transform: translate(-50%, -50%);
	@include media-breakpoint-down(xs) {
		font-size: 14px;
		top: 66%;
	}
}

.container-services .centered span {
	@include media-breakpoint-down(xs) {
		font-size: 19px;
	}
}