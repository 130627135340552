.team {
	&::after {
		background-image: url(../img/team/team-bg.jpg);
		background-position: 60% 50%;
	}
}

.team-col {
	margin-bottom: 3rem;
	h4 {
		font-size: 1.7rem;
		margin-top: 2rem;
	}
	h5 {
		position: relative;
		font-size: 1rem;
		&:after {
			content: "";
			width: 3rem;
			height: 3px;
			background-color: $primary;
			display: inline-block;
			margin-bottom: 0.35rem;
			margin-left: 1rem;
			@include media-breakpoint-down(md) {
				display: block;
				margin-left: 0;
				margin-top: 0.2rem;
			}
		}
	}
	p {
		margin-top: 1rem;
		&:before, &:after {
			content: "";
			width: 17px;
			height: 16px;
			display: inline-block;
			background-size: 17px 16px;
		}
		&:before {
			background-image: url(../img/team/quote-start.png);
			margin-right: 0.8rem;
		}
		&:after {
			background-image: url(../img/team/quote-end.png);
			margin-left: 0.5rem;
		}
	}
}

.team-point {
  h3:after {
    margin-left: 0;
  }
  ul {
    font-size: 0.8rem;
    letter-spacing: 0.15em;
  }
}
