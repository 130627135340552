// Variables

// Restated Bootstrap Variables

$white: #fff ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #adb5bd ;
$gray-600: #868e96 ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #212529 ;
$black: #000 ;

$primary: #e30613;
$secondary: #878786;
$shade: #555;
$darkgrey: #232323;