.intro {
	background: rgb(0,0,0);
	background: rgba(0,0,0,0.85);
	position: relative;
	.line {
		height: 8rem;
	}
	.intro-inner {
		margin: 4rem 0;
		@include mobilespace;
	}
	.intro-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(../img/intro-bg.jpg);
		background-size: cover;
		background-position: center;
		opacity: 0.2;
	}
}