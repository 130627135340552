.about {
	&::after {
		background-image: url(../img/about/who-bg.jpg);
		background-position: 30% 50%;
	}
}

.content h2,
.content h2 span {
	font-size: 2.5rem !important;
}

.content .front {
	 font-size: 2rem !important;
}

