.leggo {
	.leggo-inner {
		margin: 4rem 0;
		@include mobilespace;
	}
	.line {
		height: 4rem;
	}
	.leggo-col {
		display: inline-block;
		p {
			@include heading-font;
			background-color: $white;
			color: #555;
			padding: 1rem 1rem;
			text-transform: uppercase;
			@include smoothtrans();
      @include media-breakpoint-down(sm) {
        padding: 1rem 0;
      }
		}
		&:hover {
			text-decoration: none;
			p {
				background-color: $primary;
				color: $white;
			}
		}
	}
}