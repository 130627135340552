.career {
	&::after {
		background-image: url(../img/career/career-bg.jpg);
		background-position: 30% 50%;
	}
	h2 {
		span {
			&.red {
				font-size: 2rem;
			}
		}
	}
}

.career-list {
	.career-col {
		margin-top: 2rem;
		margin-bottom: 2rem;
		text-align:left;
		h3 {
			font-size: 2rem;
			margin-bottom:1em;
			&:after {
				display: none;
			}
		}
		h4 {
			@include heading-font;
			font-size: 1.2rem;
			color: $shade;
			margin-bottom: 0.6rem;
			text-transform: capitalize;
			color: #e30613;
		}
	}
}


.why-01 {
	margin-top: 9rem !important;
	margin-left: 5rem !important;
}

.why-02 {
	margin-top: 4rem !important;
}

.why-02-02 {
	display:none;
}

.why-03 {
	margin-top: 5rem !important;
    margin-left: 10rem;
    width: 100% !important;
}

.why-04 {
	margin-top:6rem !important;
}

.why-05 {
	margin-left:auto;
	margin-right:auto;
	h3 {
		text-align:center;
	}
	h4 {
		text-align:center;
	}
	p {
		text-align:center;
	}
}

.img_why01 {
    z-index: -11;
	margin-left: -5rem;
	img {
		width:100%;
		height:auto;
	}
}

.img_why02 {
	margin-top: -2rem;
	z-index: -11;
	img {
		width:90%;
		float:right;
		height:auto;
	}
}

.img_why03 {
	margin-top:2rem;
	z-index: -12;
	img {
		width:100%;
	}
}

.img_why04 {
	z-index:0;
	img {
		width:100%;
	}
}

.img_why05 {
	margin-top: -26rem;
	z-index:-13;
	img {
		width:100%;
	}
}

.career-btn {
	background-color: $primary;
	color: $white;
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 0.1em;
	padding: 0.5rem 1.5rem;
	&:hover {
		text-decoration: none;
		background: $shade;
		color: $white;
	}
}

.career-cta {
	width: 100%;
	text-align: center;
	margin-top:2rem;
	margin-bottom:6rem;
}

.line.aos-init.aos-animate {
    z-index: 0;
}

.section-content_wrapper .line {
	height: 7rem !important;
}

.line-01 {
  margin-left: 0;
  margin-top: 2rem;
}

.line-02 {
  margin-left: 3rem;
  margin-top: 3rem;
}

.line-03 {
  margin-left: 3rem;
  margin-top: 3rem;
}

.line-04 {
	margin-bottom: 2rem;
}

.line-05 {
	margin-bottom: 2rem;
}




@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) { 

	.why-01 {
		margin-top: 5rem !important;
	}

	.why-02-02 {
		display:none;
	}
	
	.why-04-02 {
		display:none;
	}
	

	.img_why01 {
		img {
			width:100%;
		}
	}
	
	.img_why02 {
		margin-top:1rem;
		img {
			width:80%;
		}
	}
	

	.img_why05 {
		margin-top: -25rem;
		img {
			width:100%;
		}
	}
	  
}

@media screen 
  and (min-width: 990px) 
  and (max-width: 1199px) { 

	.why-01 {
		margin-top:5rem !important;
	}

	.why-02-02 {
		display:none;
	}
	
	.why-04-02 {
		display:none;
	}
	

	.why-03 {
		margin-left: 5rem;
	}

	.img_why01 {
		img {
			width:120%;
		}
	}

	.img_why02 {
		margin-top:-2rem;
	}

	.img_why03 {
		margin-top:-1rem;
		margin-left:-1rem;
		img {
			width:100%;
		}
	}

	.img_why05 {
		margin-top: -25rem;
		img {
			width:100%;
		}
	}
	  
}

@media screen 
  and (min-width: 768px) 
  and (max-width: 989px) { 

	.why-01 {
		margin-top:5rem !important;
		margin-left:0 !important;
	}

	.why-02-02 {
		display:none;
	}
	
	.why-04-02 {
		display:none;
	}
	

	.why-03 {
		margin-left: 5rem !important;
	}

	.why-04 {
		margin-top: 2rem !important;
	}


	.img_why01 {
		margin-left:0 !important;
		img {
			width:110%;
		}
	}

	.img_why02 {
		margin-top:3rem;
		img {
			width: 110%;
		}
	}

	.img_why03 {
		margin-top:-1rem;
		img {
			width:100%;
		}
	}

	.img_why05 {
		margin-top: -16rem;
		img {
			width:100%;
		}
	}

	.line-02 {
		margin-left:2rem;
	}
	  
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 500px) { 

	.why-01 {
		margin-top:2rem !important;
		margin-left:0 !important;
		h3 {
			text-align: center !important;
		}
		h4 {
			text-align: center !important;
		}
		p {
			text-align: center !important;
		}
	}

	.why-02-01 {
		display:none;
	}

	.why-02-02 {
		display:block;
	}

	.why-02 {
		margin-top:7rem !important;
		margin-left:0 !important;
		h3 {
			text-align: center !important;
		}
		h4 {
			text-align: center !important;
		}
		p {
			text-align: center !important;
		}
	}

	.why-03 {
		margin-top:7rem !important;
		margin-left:0 !important;
		h3 {
			text-align: center !important;
		}
		h4 {
			text-align: center !important;
		}
		p {
			text-align: center !important;
		}
	}

	.why-04-01 {
		display:none;
	}

	.why-04-02 {
		display:block;
	}

	.why-04 {
		margin-top:2rem !important;
		margin-left:0 !important;
		h3 {
			text-align: center !important;
		}
		h4 {
			text-align: center !important;
		}
		p {
			text-align: center !important;
		}
	}

	

	.why-05 {
		margin-top:2rem !important;
		margin-left:0 !important;
		h3 {
			text-align: center !important;
		}
		h4 {
			text-align: center !important;
		}
		p {
			text-align: center !important;
		}
	}


	.img_why01 {
		margin-left:0 !important;
		margin-top:1rem !important;
		img {
			width:100%;
		}
	}

	.img_why02 {
		margin-left:0 !important;
		margin-top:1rem !important;
		img {
			width:100%;
		}
	}

	.img_why03 {
		margin-left:0 !important;
		margin-top:1rem !important;
		img {
			width:100%;
		}
	}
	
	.img_why04 {
		margin-left:0 !important;
		margin-top:1rem !important;
		img {
			width:100%;
		}
	}

	.img_why05 {
		margin-left:0 !important;
		margin-top:-11rem !important;
		img {
			width:100%;

		}
	}

	.line-02 {
		margin-top: 22rem !important;
		margin-left:45%;
		margin-right:45%;
	}
	.line-03 {
		margin-top: -9rem !important;
		margin-left:45%;
		margin-right:45%;
	}
  .line-04 {
		margin-top: -2rem;
	}
	.line-05 {
		margin-top: -4rem !important;
		margin-left:50%;
		margin-right:50%;
	}
	.line-06 {
		display: block;
		margin-top: -9rem !important;
		margin-bottom:2rem;
		margin-left:50%;
		margin-right:50%;
	}
}

//by rae start from here
.why-05 {
  margin-top: 4rem!important;
}
@media (max-width:767px) {
  .line-01, .line-02, .line-03, .line-04, .line-05 {
    display: none;
  }
  .why-01 {
    margin-top: 3rem!important;
    margin-left: auto!important;
  }
  .img_why01 {
    margin-left: auto!important;
  }
  .img_why02 img {
    float: none;
  }
  .why-03 {
    margin-left: auto;
  }
  .img_why05 {
    margin-top: -10rem;
  }
  .why-05 h3, .why-05 h4, .why-05 p, .why-05 .career-cta {
    text-align: left;
  }
}
@media (max-width:500px) {
  .why-05 .career-cta {
    text-align: center;
  }
  .why-05 {
    margin-top: 7rem!important;
  }
}