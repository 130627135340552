.section {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-size: cover;
		opacity: 0.2;
		z-index: -1;
	}
	.section-inner {
		margin-top: 7rem;
		@include mobilespace;
		.line {
			height: 4rem;
			margin-top: 3rem;
		}
	}
}

.section-content_wrapper {
	.line {
		height: 5rem;
	}
	.section-content {
		padding: 5rem 0;
		@include mobilespace;
	}
}