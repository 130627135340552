.contact {
	&::after {
		background-image: url(../img/contact/contact-bg.jpg);
		background-position: 60% 50%;
	}
}

.contact-address {
	.company {
		font-size: 1rem;
		color: $primary;
		font-weight: bold;
	}
	a {
		color: $white;
		&:hover {
			text-decoration: none;
			color: $primary;
		}
	}
	.contact-number {
		margin-top: 1.5rem;
	}
}
.contact-icons {
	margin-top: 2.5rem;
	.list-inline-item {
		&:nth-child(2) {
			margin-right: 0.7rem;
		}
		&:nth-child(3) {
			margin-right: 0.8rem;
		}
		a {
			display: inline-block;
			&:hover {
				opacity: 0.5;
			}
			img {
				height: 40px;
			}
		}
	}
}
.contact-form {
	@include media-breakpoint-down(md) {
		margin-top: 3rem;
	}
}

form {
	.form-group {
		margin-bottom: 0.4rem;
	}
	.form-control {
		font-size: 0.9rem;
		border-radius: 0;
		border: 1px solid #000;
	}
	.form-check {
		font-size: 0.9rem;
		margin-top: 1rem;
		margin-bottom: 2rem;
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
}


