@import url("https://use.typekit.net/tdi5uke.css");
// Font Mixins
@mixin body-font {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
	font-weight: 300;
	font-style: normal;
}
@mixin heading-font {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin mobilespace {
	@include media-breakpoint-down(xs) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@mixin smoothtrans {
	transition: all 0.3s ease;
}

@mixin animatethis {
	animation-duration: 1s;
	animation-fill-mode: both;
}