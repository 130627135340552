
#foreward-realty {
  min-height: 100vh !important;

  .title-masthead {
    font-size: 48px;
    position: relative;
  
    &:after {
      content: "";
      width: 20%;
      height: 5px;
      background-color: $primary;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      margin: auto;
    }
    @include media-breakpoint-only(md) {
      font-size: 70px;
    }
  }

  .cover-inner .logo {
    margin-bottom: 0rem;
  }

  .cover-inner .ffs {
    margin-bottom: 3rem;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .back-btn {
    font-size: .8rem !important;
    width: 4rem;
    height: 2.5rem;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background-color: #e30613;
    font-family: itc-avant-garde-gothic-pro,sans-serif;
    font-weight: 300;
    font-style: normal;
    cursor: pointer;
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      background: #555;
      color: #fff;
    }
  }

}
