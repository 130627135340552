.cover-inner {
	@include mobilespace;
	.logo {
		width: 550px;
		margin-bottom: 2rem;
	}
	.ffs {
		width: 200px;
		margin-bottom: 6rem;
	}
}

.scroll {
	p {
		font-size: 0.55rem;
		color: $primary;
		text-transform: uppercase;
		margin-bottom: 0.5rem;
		letter-spacing: 0.1rem;
	}
	.line {
		height: 1.5rem;
	}
}