.insight-article {
	&::after {
		background-image: none;
	}
  .section-inner {
    margin-top: 0;
    margin-bottom: 100px;
    position: relative;
    padding: 0;
    @include media-breakpoint-down(md) {
     margin-bottom: 60px; 
    }
    img {
      display: block;
      width: 100%;
    }
    .post-title {
      min-height: 220px;
      background: #fff;
      color: #151515;
      box-sizing: border-box;
      padding: 40px 0;
      padding-left: 160px;
      padding-right: 70px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 74.479167%;
      @include media-breakpoint-down(md) {
        padding: 30px;
        position: relative;
        width: 100%;
      }
      .typedate {
         margin-bottom: 40px;
        .type {
          float: left;
          letter-spacing: 1.5pt;
          text-transform: uppercase;
          width: 50%;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
        .date {
          float: right;
          @include media-breakpoint-down(md) {
            float: none;
          }
        }
      }
      h2 {
        @include heading-font();
        text-transform: none;
        font-size: 1.5rem;
        max-width: 80%;
        margin-bottom: 0;
        &:after {
          margin-left: 0;
        }
        @include media-breakpoint-down(md) {
           max-width: 100%;
        }
      }
    }
  }
  
  .section-content_wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 945px;
    width: 100%;
    color: #151515;
    margin-bottom: 7rem;
    h3 {
      text-transform: none;
      font-size: 1.8rem;
      &:after {
        margin-left: 0;
      }
    }
    p {
      font-size: 0.95rem;
      letter-spacing: 0.1em;
      margin-bottom: 1.5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
    figure {
      font-size: 13px;
      a {
        color: #151515;
      }
      figcaption {
        margin-top: 0.5rem;
        i {
          font-size: 13px;
        }
      }
    }
  }
}

.post-tag {
    margin-bottom: 3rem;
  a {
    display: inline-block;
    //float: left;
    position: relative;
    margin: 0.615em 0.615em 0 0;
    border: 1px solid #ddd;
    border: 1px solid rgba(0,0,0,0.125);
    padding: 0.692em 1.154em 0.769em;
    //cursor: pointer;
    font-size: 11px;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    color: #c5c5c5;
    color: rgba(0,0,0,0.375);
    background-color: transparent;
    border-radius: 0.35em;
    box-shadow: inset 0 0 0 transparent, 0 1px 1px rgba(255,255,255,0.95);
  }
}

