@keyframes fadeFromBtm {
	0%, 55% { opacity: 0; transform: translate3d(0,40px,0); }
	100% { opacity: 1; transform: translate3d(0,0,0); }
}
.fadeFromBtm {
  animation-name: fadeFromBtm;
}

@keyframes fadeFromTop {
	0%, 55% { opacity: 0; transform: translate3d(0,-40px,0); }
	100% { opacity: 1; transform: translate3d(0,0,0); }
}
.fadeFromTop {
  animation-name: fadeFromTop;
}

@keyframes fadeIn {
	0%, 55% { opacity: 0; }
	100% { opacity: 1; }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes zoomIn {
	0%, 55% { opacity: 0; transform: scale(1.2); }
	100% { opacity: 1; transform: scale(1); }
}
.zoomIn {
  animation-name: zoomIn;
}

.animated {
	animation-delay: .05s;
	@include animatethis();
}
.animated-2 {
	animation-delay: .22s;
	@include animatethis();
}
.animated-3 {
	animation-delay: .3s;
	@include animatethis();
}
.animated-4 {
	animation-delay: .4s;
	@include animatethis();
}
.animated-5 {
	animation-delay: .5s;
	@include animatethis();
}
.animated-6 {
	animation-delay: .5s;
	@include animatethis();
}
.animated-7 {
	animation-delay: .7s;
	@include animatethis();
}