.bg-white {
    background-color: white;
}

.color-black {
    color: black;
}

.fiesta-form .form-control {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
}

.information-divider {
    height: 3px;
    background: linear-gradient(to right, #ffca00 0%,#e95907 23%,#d9000d 43%,#9a0928 62%,#3c1952 87%,#171f63 100%);
    margin: 15px 0px 25px;
}

.information-data {
    font-size: 0.9em;
}

.gradient-body {
    background: linear-gradient(to right, #ffca00 0%,#e95907 23%,#d9000d 43%,#9a0928 62%,#3c1952 87%,#171f63 100%);
    padding: 1px;
    border-radius: 10px;
    display: inline-block;

    span {
        background: white;
        padding: 5px 10px;
        border-radius: 10px;
        display: inline-block;
    }
}

.text-md {
    font-size: 1em;
}