// Styling for the navbar
#mainNav {
	padding: 1rem 0;
	@include media-breakpoint-down(xs) {
		padding: 1rem;	
	}
  .navbar-toggler {
    font-size: 0.8rem;
    width: 4rem;
		height: 2.5rem;
    text-transform: uppercase;
    color: $white;
    border: 0;
    background-color: $primary;
    @include body-font;
  }
  .navbar-brand {
		img {
			width: 140px;
			@include smoothtrans();
		}
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 90%;
        font-weight: 400;
        padding: 0.75em 0;
        color: $white;
        @include body-font;
        &:hover {
          color: $primary;
        }
				&.active {
					color: $primary;
					font-weight: bold;
				}
				@include media-breakpoint-up(lg) {
					padding: 1.1em 1em !important;
				}
      }
			&.nav-link-dropdown {
				position: relative;
				.nav-link {
					&:focus {
						color: $primary;
					}
				}
			}
    }
  }
}

.dropdown-menu {
	border-radius: 0;
	font-size: 0.9rem;
	.dropdown-item {
		color: $shade;
	}
	&.show {
		.dropdown-item {
			&:hover {
				color: $primary;
			}
			&:active {
				color: $white;
			}
			&.active {
				color: $white;
			}
		}
	}
}

